const Adjectives = [
  "big",
  "bad",
  "soft",
  "powerful",
  "mysterious",
  "courageous",
  "friendly",
  "courteous",
  "small",
  "definitive",
  "delicious",
  "delightful",
  "diligent",
  "horrendous",
  "unsweetened",
  "derogatory",
  "fluffy",
  "sharp",
  "idiotic",
  "significant",
  "graceful",
  "talented",
  "wise",
  "quick",
  "stingy",
  "diabolical",
  "bashful",
  "blunt",
  "dreamy",
  "kind",
  "variable",
  "unsafe",
  "curious",
  "monotonous",
  "lofty",
  "crafty",
  "cantankerous",
  "slippery",
  "rough",
  "rigid",
  "simplistic",
  "shady",
  "bright",
  "tall",
  "loud",
  "antagonistic",
  "furious",
  "esoteric",
  "lucky",
  "calm",
  "gifted",
  "strong",
  "lovable",
  "pointy",
  "cute",
  "standard",
  "basic",
  "curly",
  "angry",
  "cowardly",
  "misplaced",
  "ignorant",
  "flighty",
  "hungry",
  "flexible",
  "brilliant",
  "difficult",
  "mercurial",
  "obedient",
  "fast",
  "fair",
  "insouciant",
  "marketable",
  "troublesome",
  "heartwarming",
  "stubborn",
  "impatient",
  "impudent",
  "impulsive",
  "hip",
  "twisted",
  "casual",
  "substantial",
  "muddled",
  "dusty",
  "fat",
  "excited",
  "vulgar",
  "trite",
  "wet",
  "questionable",
  "witty",
  "crooked",
  "cluttered",
  "helpless",
  "gruesome",
  "bubbly",
  "giant",
  "illustrious",
  "luxurious",
  "forgetful",
  "momentous",
];

const Nouns = [
  "bunny",
  "dog",
  "cat",
  "spouse",
  "cup",
  "swingset",
  "slide",
  "clubhouse",
  "candle",
  "flagpole",
  "fountain",
  "hearth",
  "cellphone",
  "doorway",
  "tunnel",
  "highway",
  "village",
  "football",
  "hideout",
  "cult",
  "star",
  "sidewalk",
  "theatre",
  "flower",
  "applesauce",
  "dream",
  "drawing",
  "shop",
  "deoderant",
  "webpage",
  "tollway",
  "bathroom",
  "rollercoaster",
  "poster",
  "teapot",
  "lawnmower",
  "ballgame",
  "nerd",
  "horse",
  "spear",
  "bison",
  "lemur",
  "giraffe",
  "mountainrange",
  "port",
  "casino",
  "spyglass",
  "award",
  "beetleborg",
  "chef",
  "tree",
  "robot",
  "empire",
  "avatar",
  "bicycle",
  "claw",
  "birdman",
  "teammate",
  "bluray",
  "raspberry",
  "banana",
  "dinner",
  "party",
  "person",
  "actor",
  "leader",
  "superhero",
  "cloud",
  "thunderstorm",
  "rainstorm",
  "email",
  "hayride",
  "playground",
  "timeline",
  "reality",
  "lifestyle",
  "aesthetic",
  "mood",
  "timezone",
  "country",
  "vase",
  "landmark",
  "milestone",
  "skyscraper",
  "butt",
  "sandlot",
  "garage",
  "friend",
  "phonecall",
  "garden",
  "microphone",
  "speaker",
  "huddle",
  "ghost",
  "boardroom",
  "boardmeeting",
  "investor",
  "comet",
  "meteor",
  "sandwich",
  "meal",
  "dmv",
  "magician",
  "comedian",
  "printer",
];

export const CreateID = () => {
  const adj1 = Adjectives[Math.floor(Math.random() * Adjectives.length)];
  const adj2 = Adjectives[Math.floor(Math.random() * Adjectives.length)];
  const adj3 = Adjectives[Math.floor(Math.random() * Adjectives.length)];
  const noun = Nouns[Math.floor(Math.random() * Nouns.length)];

  return `${adj1}-${adj2}-${adj3}-${noun}`;
};
